import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormArray, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Environment } from 'src/app/data/model/environment';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { generateRandomString, Position, RESOURCES, Type } from 'src/app/utils/const';
import { App, ImageType, IngressPath, IngressType, LifeCycle, Metadata, PostStart } from 'src/app/data/model/apps';
import { Application, Domain, Registry } from 'src/app/utils/types';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { ShareService } from 'src/app/data/service/share.service';
import { ProjectService } from '../../onboard/services/project.service';
import { ClusterService } from '../../clusters/services/cluster.service';
import { Cluster } from 'src/app/data/model/cluster';
import { decodeToBase64, encodeToBase64 } from 'src/app/utils/helpers';
import { Deployment, DeploymentSpecs, Ingress } from 'src/app/data/model/deployment';

type AppEnvType = {
  id: string,
  applicationId: string,
  environmentId: string,
  key: string,
  value: string,
}
type DeployType = {
  host: string,
  applicationEnvironments: AppEnvType[],
  isExposed: boolean,
}

@Component({
  selector: 'app-create-deployment',
  templateUrl: './create-deployment.component.html',
  styleUrls: ['./create-deployment.component.scss'],
})
export class CreateDeploymentComponent implements OnInit {
  application: Application;
  deployAppForm: UntypedFormGroup;
  currentFirstDeploy: App;
  environments: Environment[] = [];
  registry: Registry;
  loading: boolean = false;
  isExposed: boolean = true;
  loadingEnvs: boolean = false;
  buildConfig: any = {};
  loadingBuildConfig: boolean = false;
  buildconfigs: any = [];
  currentBuildConfig: any;
  isASocket: boolean = false;
  portError: boolean = false;
  resources = RESOURCES;
  defaultEnvironments = ['dev', 'preprod', 'prod'];
  advanced = false;
  domains: Domain[] = [];
  sharedDomain: boolean = false;
  domainSelected: Domain;
  submitButtonState: boolean = false;

  filteredOptions: Environment[] = [...this.environments];
  defaultOptions: Environment[] = []
  envSelected: Environment

  showAddButton: boolean = false;
  isEnvSelected: boolean = false
  isDomainSelected: boolean = false
  inputName: string

  filteredDomainOptions: Domain[] = [...this.domains];
  defaultDomainOptions: Domain[] = []

  clusters: Cluster[] = []
  isClusterSelected: boolean = false
  envVariableFormArray: FormArray
  globalLoader: boolean = false

  constructor(
    public auth: AppAuthGuardService,
    public modalRef: BsModalRef,
    private appService: AppService,
    private service: AsideExtenderService,
    private shareService: ShareService,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private clusterService: ClusterService,


  ) {
  }

  ngOnInit(): void {
    if (this.application.id) {
      this.createForm();
      this.getBuildconfigPipeline();
      this.getRegistry();
      this.getClusters()
    }
    this.envVariableFormArray = new FormArray([], this.noDuplicateKeys);
  }

  createForm(){
    this.deployAppForm = this.fb.group({
      subdomain: this.fb.control('', [Validators.pattern('^[^.]*$')]),
      domain: this.fb.control('', [Validators.required]),
      env: this.fb.control('', Validators.required),
      port: this.fb.control('', [Validators.required, Validators.min(1)]),
      postStartCmd: this.fb.control(''),
      ingressPath: this.fb.control('/', [Validators.required, Validators.pattern(/^\/([\w/-]*)(,\/[\w/-]+)*$/)]),
      buildConfig: this.fb.control('', [Validators.required]),
      environmentVariables: this.fb.array([], this.noDuplicateKeys),
      cluster: this.fb.control(''),

    });
  }
    setAppForm(env: string, port: string, postStartCmd: string,ingressPath: string, buildConfig: string,subdomain: string, domain: string, cluster?: string ){

      this.deployAppForm.patchValue({
        subdomain: subdomain ,
        domain: domain,
        env: env,
        port: port,
        postStartCmd: postStartCmd,
        ingressPath: ingressPath,
        buildConfig: buildConfig,
        cluster: cluster,
      })
    }

  createEnvVar(key: string, value: string): FormGroup {
    return this.fb.group({
      key: [key, [Validators.required, Validators.pattern('^[a-zA-Z][a-zA-Z0-9_]*$')]],
      value: [value, [Validators.required]]
    });
  }


  onAddEnvironmentVariable(key: any, value: any) {
    this.envVariableFormArray.push(this.createEnvVar(key, value))
    this.deployAppForm.setControl('environmentVariables',this.envVariableFormArray)
  }

  onRemoveEnvironmentVariable(index: number) {
    this.envVariableFormArray.removeAt(index);

    this.deployAppForm.get('environmentVariables').updateValueAndValidity();
    this.deployAppForm.updateValueAndValidity();
  }

  noDuplicateKeys(formArray: FormArray) {
      const keyValues = formArray.controls.map(control => control.get('key').value);
      const keyOccurrences = keyValues.reduce((acc, key) => {
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      formArray.controls.forEach((control, index) => {
        const keyControl = control.get('key');
        if (keyOccurrences[keyControl.value] > 1) {
          keyControl.setErrors({ duplicate: true });
        } else {
          if (keyControl.hasError('duplicate')) {
            delete keyControl.errors['duplicate'];
            if (!Object.keys(keyControl.errors).length) {
              keyControl.setErrors(null);
            }
          }
        }
      });

      return keyValues.some(key => keyOccurrences[key] > 1) ? { duplicateKeys: true } : null;
  }

  onChangeIsExposed(event: any) {
    this.isExposed = event.target.checked;
    // to remove after debug
    //this.checkHostField();
  }

  filterEnv (param: string) {
    this.inputName = param
    this.filteredOptions = this.environments?.filter((option : Environment) =>
      option?.name.toLowerCase().includes(this.deployAppForm.value.env.toLowerCase())
    );
      this.isEnvSelected = false;
     if((this.deployAppForm.value.env == undefined && this.deployAppForm.value.env == "") && this.filteredOptions.length === 0) {
      this.defaultOptions = this.environments
      this.showAddButton = false

    }else if(this.deployAppForm.value.env !== undefined && this.filteredOptions.length !== 0 ){
      this.defaultOptions = this.filteredOptions
      this.showAddButton = false

    }else {
      this.defaultOptions = [];
      this.showAddButton = true

    }
  }

  filterDomain(param: string) {
    this.inputName = param
    this.filteredDomainOptions = this.domains?.filter((option : Domain) =>
     option?.host.toLowerCase().includes(this.deployAppForm.value.domain.toLowerCase())
  );
      this.isDomainSelected = false;
     if((this.deployAppForm.value.domain == undefined && this.deployAppForm.value.domain == "") && this.filteredDomainOptions.length === 0) {
      this.defaultDomainOptions = this.domains
      this.showAddButton = false

    }else if(this.deployAppForm.value.domain !== undefined && this.filteredDomainOptions.length !== 0 ){
      this.defaultDomainOptions = this.filteredDomainOptions
      this.showAddButton = false

    }else {
      this.defaultDomainOptions = [];
      this.showAddButton = true

    }
  }

  myFunction(param: string) {
    this.inputName = param
    if((this.deployAppForm.value.env === undefined || this.deployAppForm.value.env === '') && this.filteredOptions.length === 0 && param == "env") {
      this.defaultOptions = this.environments
      this.showAddButton = false
      }
    if((this.deployAppForm.value.domain === undefined || this.deployAppForm.value.domain === '') && this.filteredDomainOptions.length === 0 && param == "domain") {
      this.defaultDomainOptions = this.domains
      this.showAddButton = false
      }
  }

 selectOption(option: any , param: string) {
    this.inputName = param
    if(param === "env"){
      this.globalLoader = false;
      this.envSelected = option;
      this.filteredOptions = [];
      this.deployAppForm.patchValue({
        env: option?.name
      });
      this.getDomains(this.envSelected);
      this.isEnvSelected = true;
    }
    if(param === "domain"){
      this.domainSelected = option;
      this.filteredDomainOptions = [];
      this.deployAppForm.patchValue({
        domain: option?.host
      });
      this.isDomainSelected = true;
    }
  }

  addNewEnv() {
    if(this.deployAppForm.value.cluster == ""){
    this.isClusterSelected = true

    }else {
      const envInfos : any = {
        name: this.deployAppForm.value.env,
        clusterId: this.deployAppForm.value.cluster.id,
        projectId: this.application.projectId
      }
      this.loadingEnvs = true
      this.projectService.createEnvironmentNotQuota(envInfos).subscribe(
        async (data) => {
          this.isEnvSelected = true;
          this.appService
          .getEnvironmentByApplication(this.application.projectId)
          .subscribe(
            (response: any) => {
              this.environments = response.records;
              this.loadingEnvs = false;
              this.deployAppForm.patchValue({
               env: envInfos.name,
             });
             this.envSelected = this.environments?.find(
               (env) => env.name === this.deployAppForm.value.env
             )

            }
          );
          this.service.show({
            title: 'Environment',
            position: Position.TOP,
            type: Type.SUCCESS,
            message: 'Created successfully',
          });
        },
        (error) => {
          this.loadingEnvs = false
          this.service.show({
            title: 'Error Environment',
            position: Position.TOP,
            type: Type.ERROR,
            message: error.error,
          });
        }
      );

    }
  }

  checkHostField() {
    if (this.isExposed) {
      this.deployAppForm
        .get('host')
        .addValidators([
          Validators.required,
          Validators.pattern(
            /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,10}(\/[-a-zA-Z0-9@:%._\+~#?&//=]*)?$/
          ),
        ]);
    } else {
      this.deployAppForm.get('host').clearValidators();
    }
    this.deployAppForm.get('host').updateValueAndValidity();
    this.deployAppForm.updateValueAndValidity();
  }

  onChangeIsASocket(event: any) {
    this.isASocket = event.target.checked;
  }

 getEnvironments() {
    this.loadingEnvs = true;
    this.appService
      .getEnvironmentByApplication(this.application.projectId)
      .subscribe(
         (response: any) => {
          this.environments = response.records;
          this.loadingEnvs = false;
          this.globalLoader = true
          if (this.environments.length == 1) {
            this.envSelected = this.environments[0]
            this.getDomains(this.envSelected);
            this.deployAppForm.patchValue({
              env: this.environments[0]?.name
            });
          }
        },
        (error) => {
          this.globalLoader = true
          this.loadingEnvs = false;
        }
      );
  }

  getBuildconfigPipeline() {
    this.loadingBuildConfig = true;
    this.appService
      .getBuildconfigPipeline(this.application.projectId, this.application.id)
      .subscribe((buildconfigs: any) => {
        this.buildconfigs = buildconfigs;
        this.loadingBuildConfig = false;
      });
  }

  getRegistry() {
    this.appService.getRegistryById(this.application.registryId).subscribe(
      (response: any) => {
        this.registry = response;
      },
      (error) => {
        console.log(error.error);
      }
    );
  }

  getFirstDeploy(option: Environment): void {
    this.appService
      .getDeploymentInEnvironment(
        this.application?.id,
        option?.id,
        this.application?.projectId
      )
      .subscribe(
        (response: App) => {
          this.currentFirstDeploy = response;
          let ingress = this.currentFirstDeploy?.spec?.ingress;
          if (Array.isArray(ingress)) {
            ingress = this.currentFirstDeploy?.spec?.ingress[0];
          }
          this.currentBuildConfig = this.buildconfigs.find(
            (bc: { id: string }) => bc.id === this.currentFirstDeploy?.buildConfigId
          );
          let value = undefined ;
          for (let index = 0; index < this.domains.length; index++) {
            const option = this.domains[index];
            const subdomain = ingress?.host.toLowerCase().replace(option?.host.toLowerCase(), '')
            const count = (subdomain.match(/\./g) || []).length;
            if (count == 1 && subdomain !== ingress?.host) {
              value = option;
              break;
            }
          }

          if(!value){
            for (let index = 0; index < this.domains.length; index++) {
              const option = this.domains[index];
              const subdomain = ingress?.host.toLowerCase().replace(option?.host.toLowerCase(), '')
              if(subdomain == ''){
                  value = option
                  break;
              }
            }
          }
          this.domainSelected = value
          let subdomain = ingress?.host?.replace(this.domainSelected?.host, '');
          if(subdomain !== ""){
            subdomain = subdomain.replace('.', '')
          }
          this.envVariableFormArray = new FormArray([], this.noDuplicateKeys);
          if (this.currentFirstDeploy?.spec?.env) {

            let keys = Object.keys(this.currentFirstDeploy?.spec?.env);
            let values = Object.values(this.currentFirstDeploy?.spec?.env);

            for (let index = 0; index < keys.length; index++) {
              const key = keys[index];
              const value: any = values[index];
              this.onAddEnvironmentVariable(key, value);
            }
          }

          const ingressPaths = ingress.paths;

          let ingressPathString = '';
          for (let index = 0; index < ingressPaths.length; index++) {
            const ingressPath = ingressPaths[index];
            if (ingressPathString == '') {
              ingressPathString = ingressPath.path;
            } else {
              ingressPathString += ',' + ingressPath.path;
            }
          }

          this.setAppForm(
            this.currentFirstDeploy?.environmentName,
            this.currentFirstDeploy?.spec?.port.toString(),
            this.currentFirstDeploy?.spec?.lifecycle?.postStart?.command,
            ingressPathString,
            this.currentBuildConfig,
            subdomain,
            this.domainSelected?.host
          );

          let label: any = ingress?.labels;
          let defaultKey = 'door.cloudoor.com/enable-websockets';
          this.isExposed = ingress?.enabled;
          this.deployAppForm.get("buildConfig").disable()

          let values = Object.values(label);
          let keys = Object.keys(label);

          for (let index = 0; index < values.length; index++) {
            let value: any = values[index];
            const key = keys[index];
            if (defaultKey == key) {
              if (value == 'true') {
                this.isASocket = true;
              } else {
                this.isASocket = false;
              }
            }
          }
          this.globalLoader = true;
        },
        (error) => {
          let random = generateRandomString(6)
          this.currentFirstDeploy = null;
          this.currentBuildConfig = null;
          this.envVariableFormArray = new FormArray([], this.noDuplicateKeys);

          this.deployAppForm.get("buildConfig").enable()
          this.setAppForm(
            this.deployAppForm?.value?.env,
            "",
            "",
            "/",
            "",
            `${this.application.name}-${random}`,
            this.deployAppForm?.value?.domain
          )
          this.globalLoader = true;
        }
      );
  }
  getClusters(): void {
    this.clusterService.getAll().subscribe((data) => {
      this.clusters = data.records;
      this.getEnvironments();
      if(this.clusters.length == 1){
        this.deployAppForm.patchValue({
          cluster: this.clusters[0]
        });
        if(this.clusters[0]?.shared == true ) {
          this.sharedDomain = true;
          this.deployAppForm.controls['subdomain'].disable();
        }
      }
    });
  }

  onSelectChange(item: Cluster){
    if(item){
      if(item?.shared === true){
        this.sharedDomain = true;

      }else {
        this.sharedDomain = false;

      }
      this.isClusterSelected = false
    }
  }

  onChangeConfigration(): void {
    if(this.buildconfigs.length == 1) {
      this.currentBuildConfig = this.buildconfigs[0];
      this.deployAppForm.controls['buildConfig'].setValue(this.currentBuildConfig);
    }
    this.currentBuildConfig = this.deployAppForm.get('buildConfig').value;
    if (!this.canRunOrDeploy(this.currentBuildConfig)) {
      this.deployAppForm.get('buildConfigId').setValue('');
    }
  }

  canRunOrDeploy(buildConfig: { name: string; projectId: string }) {
    let canRunOrDeploy = false;

    if (['dev', 'preprod'].includes(buildConfig?.name)) {
      if (
        this.auth?.hasProjectRoles(
          this.resources.project.role.developer,
          this.currentBuildConfig?.projectId
        )
      ) {
        canRunOrDeploy = true;
      }
    }

    if (this.defaultEnvironments.includes(buildConfig?.name)) {
      if (
        this.auth?.hasOrganizationRoles('super_admin') ||
        this.auth?.hasOrganizationRoles('owner+') ||
        this.auth?.hasOrganizationRoles('owner') ||
        this.auth?.hasProjectRoles('admin', buildConfig?.projectId)
      ) {
        canRunOrDeploy = true;
      }
    }

    if (!this.defaultEnvironments.includes(buildConfig?.name)) {
      canRunOrDeploy = true;
    }

    return canRunOrDeploy;
  }

  canUseEnv(environment: Environment) {
    let canUseEnv = false;
    if (environment?.isProd) {
      if (
        this.auth?.hasOrganizationRoles('super_admin') ||
        this.auth?.hasOrganizationRoles('owner+') ||
        this.auth?.hasOrganizationRoles('owner') ||
        this.auth?.hasProjectRoles('admin', this.application?.projectId)
      ) {
        canUseEnv = true;
      }
    } else {
      canUseEnv = true;
    }
    return canUseEnv;
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  verifySlash(chaine: string) {
    if (!chaine.startsWith('/')) {
      chaine = '/' + chaine;
    }
    return chaine;
  }

  onDeployApp() {
    this.submitButtonState = true;
    let app: Deployment = {} as Deployment;
    let orgName = this.shareService.organizationSelected.name;
    let appName = this.application.name;

    if (this.deployAppForm.valid) {
      this.loading = true;
      this.deployAppForm.controls['buildConfig'].enable();
      this.deployAppForm.controls['subdomain'].enable();

      app.metadata = {} as Metadata;
      app.metadata.name = appName;
      app.metadata.namespace = this.envSelected.namespace;
      app.environmentId = this.envSelected.id;
      app.registryId = this.application.registryId;
      app.projectId = this.application.projectId;
      app.codeId = this.application.id;
      app.organizationId = this.application.organizationId;
      app.buildConfigId = this.deployAppForm.value.buildConfig.id;

      if (this.registry.repo.endsWith('/')) {
        this.registry.repo = this.registry.repo.slice(0, -1);
      }

      app.spec = {} as DeploymentSpecs;
      app.spec.nameOverride = appName;

      let imageType: ImageType = new ImageType();

      imageType.repository = this.registry.repo + '/' + this.deployAppForm.value.buildConfig.imageName;
      if (['ecr'].includes(this.registry.registryType)) {
        imageType.repository = this.deployAppForm.value.buildConfig.imageName;
      }

      imageType.tag = this.deployAppForm.value.buildConfig.latestVersion;
      app.spec.image = imageType;

      app.spec.port = parseInt(this.deployAppForm.value.port);

      let ingressPaths = [];
      if (!this.deployAppForm.value.ingressPath) {
        const ingressPath = { path: '/' } as IngressPath;
        ingressPaths.push(ingressPath);
      }else{
        let splitIngressPaths = this.deployAppForm.value.ingressPath.split(',');
        for (let index = 0; index < splitIngressPaths.length; index++) {
          const path = this.verifySlash(splitIngressPaths[index]);
          const ingressPath = { path: this.verifySlash(path) } as IngressPath;
          ingressPaths.push(ingressPath);
        }
      }

      let host = this.deployAppForm.value.domain
      if(this.deployAppForm.value.subdomain !== ''){
        host = this.deployAppForm.value.subdomain+"."+this.deployAppForm.value.domain
      }
      let ingress = {
        enabled: this.isExposed,
        host: host,
        domain: this.domainSelected.host,
        domainId: this.domainSelected.id,
        subdomain: this.deployAppForm.value.subdomain,
        domainShared: this.domainSelected.shared,
        paths: ingressPaths,
        annotations: {
          'httpproxy.door.cloudoor.com/include-path': 'true',
        },
        labels: {
          'door.cloudoor.com/enable-websockets': this.isASocket.toString(),
          'door.cloudoor.com/certificat-wildcard-name':
            this.domainSelected.certificateSecretName,
          'door.cloudoor.com/certificat-wildcard-namespace':
            this.domainSelected.certificateSecretNamespace,
        },
        secretName: this.domainSelected.certificateSecretName,
      } as Ingress;
      app.spec.ingress = [ingress];

      let envs = {};
      if (this.envVariableFormArray.value.length > 0) {
        this.envVariableFormArray.value.forEach((element) => {
          const key = element.key;
          const value = element.value;

          envs[key] = value;
          // envs[key] = encodeToBase64(value);
        });
      }
      app.spec.env = envs;

      let postStart = {
        command: this.deployAppForm.value.postStartCmd,
      } as PostStart;
      app.spec.lifecycle = { postStart: postStart } as LifeCycle;

      // console.log(app);
      // return;
      if (!app.spec.image.tag) {
        this.service.show({
          title: 'Deploy application',
          message: 'The image is still building or does not exist',
          type: Type.ERROR,
          position: Position.TOP,
        });
      } else if (
        this.deployAppForm.value.port.value === 0 ||
        this.deployAppForm.value.port.value == ''
      ) {
        this.portError = true;
        this.loading = false;
        setTimeout(() => {
          this.portError = false;
        }, 2000);
      } else {
        this.loading = false;
        if (this.currentFirstDeploy?.id) {
          this.appService
            .updateDeploymentInEnvironment(
              this.currentFirstDeploy.id,
              this.envSelected.id,
              this.application.projectId,
              app
            )
            .subscribe(
              (response) => {
                this.loading = false;
                this.submitButtonState = false;
                this.onCloseDialog();
                this.service.show({
                  title: 'Deploy application',
                  message: 'Application deployed successfully',
                  type: Type.SUCCESS,
                  position: Position.TOP,
                });

              },
              (error) => {
                this.loading = false;
                this.submitButtonState = false;
                const message = error.error.match(
                  /^(.*is invalid: not match regex)/
                );
                this.deployAppForm.controls['buildConfig'].disable();
                this.deployAppForm.controls['subdomain'].disable();

                this.service.show({
                  title: 'Deploy application',
                  message: message ? message[1] : error.error,
                  type: Type.ERROR,
                  position: Position.TOP,
                });
              }
            );
        } else {
          this.appService
            .createDeploymentInEnvironment(
              this.envSelected.id,
              this.application.projectId,
              app
            )
            .subscribe(
              (response) => {
                this.loading = false;
                this.submitButtonState = false;
                this.onCloseDialog();
                this.service.show({
                  title: 'Deploy application',
                  message: 'Application deployed successfully',
                  type: Type.SUCCESS,
                  position: Position.TOP,
                });
              },
              (error) => {
                this.loading = false;
                this.submitButtonState = false;
                const message = error.error.match(
                  /^(.*is invalid: not match regex)/
                );
                this.deployAppForm.controls['buildConfig'].disable();
                this.deployAppForm.controls['subdomain'].disable();

                this.service.show({
                  title: 'Deploy application',
                  message: message ? message[1] : error.error,
                  type: Type.ERROR,
                  position: Position.TOP,
                });
              }
            );
        }
      }
    }
  }

  loadEnvs(event: any) {
    event.forEach((m: any, index: any) => {
      let key = m[0];
      if (m[0][0] == `"` || m[0][0] == `'`) {
        key = m[0].slice(1)
        key = key.slice(0,[key.length - 1])
      }
      let value = m[1];
      if (m[1][0] == `"` || m[1][0] == `'`) {
        value = m[1].slice(1)
        value = value.slice(0,[value.length - 1])
      }

      this.onAddEnvironmentVariable(key, value);
    });
  }

  getDomains(env: Environment) {
      this.appService.getDomainsByEnv(env.id).subscribe((data: any) => {
        this.domains = data;
        if(data.length == 1){
          this.deployAppForm.patchValue({
            domain: data[0]?.host
          });
          this.domainSelected = data[0];
        }
        let random = generateRandomString(6)
        if(this.deployAppForm.value.subdomain === ""){
          this.deployAppForm.controls['subdomain'].setValue(`${this.application.name}-${random}`);
        }

        if(this.domainSelected?.shared == true ) {
          this.sharedDomain = true;
          this.deployAppForm.controls['subdomain'].disable();
        } else {
          this.deployAppForm.controls['subdomain'].enable();
          this.deployAppForm.controls['domain'].enable();
          this.sharedDomain = false;
        }
        this.getFirstDeploy(this.envSelected);
    });
  }

  addNewDomain(){
      const dataToSend = {
        host: this.deployAppForm.value.domain,
        organizationId: this.shareService.organizationSelected.id,
        isUnsecure : true,
        clusterId: this.envSelected.clusterId,
        environmentId: this.envSelected.id,
        environmentName : this.envSelected.name,
    };
    this.appService.createDomain(dataToSend).subscribe(
      (_) => {
        this.deployAppForm.patchValue({
          domain: dataToSend.host,
        });
        this.appService.getDomainsByEnv(dataToSend.environmentId).subscribe((data: any) => {
          this.domains = data;

          const value = data?.filter((option : Domain) =>
            option?.host.toLowerCase().includes(this.deployAppForm.value.domain.toLowerCase())
          );
          this.domainSelected = value[0]
        });

        this.isDomainSelected = true;
        this.service.show({
          title: 'Add Domain',
          message: 'Domain created successfully',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
      },
      (er) => {
        this.service.show({
          title: 'Add Domain',
          message: er.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
      }
    );

  }


}
